body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
tr {vertical-align: baseline;}
.login-page,
.signup-page {
    background: linear-gradient(45deg, #21e1fb, #0cb9f8);
    /* background-color: #131225; */
    min-height: 100vh; /* Ensure it covers the whole screen */
    color: rgb(248, 249, 250);
}

.login-container {
    max-width: 400px !important;
    margin: auto;
    text-align: center;
    padding-top: 50px;
}

.login-page .pin-display {
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
}

.login-page .pin-dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border: 2px solid #f8f9fa;
    border-radius: 50%;
}

.login-page .pin-dot.filled {
    background-color: #f8f9fa;
}

.login-page .keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
}

.login-page .keypad-footer {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
}

.login-page .navbar-nav {
    justify-content: center;
}

.login-page .nav-link {
    color: #f8f9fa;
}

.login-page .keypad-button,
.login-page .keypad-button:active
 {
    width: 80px;
    height: 80px;
    font-size: 42px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;    
    background-color: rgba(255, 255, 255, 0.3)!important; /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    border: none; /* Remove default button border */
    color: #fff; /* Button text color */
    transition: background-color 0.25s ease; /* Add transition for background color */
}

.login-page .keypad-button.pressed {
    background-color: rgba(255, 255, 255, .7)!important; /* Change this to the desired color on press */
    border-color: rgba(198, 199, 200, 1)!important;
    color: #fff;
}

.login-page .keypad-button.invisible {
    visibility: hidden;
}

.login-page .keypad-button:focus {
    outline: none; /* Remove focus outline */
}

.login-page .fixed-bottom {
    max-width: 400px !important;
    margin-inline: auto;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(5px); }
    37.5% { transform: translateX(-15px); }
    50% { transform: translateX(15px); }
    62.5% { transform: translateX(-15px); }
    75% { transform: translateX(5px); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.5s;
}

/* Add higher specificity for the .keypad-button.active state */
.login-page .keypad-button:active,
.login-page .keypad-button:focus,
.login-page .keypad-button:hover,
.signup-page .keypad-button:active,
.signup-page .keypad-button:focus,
.signup-page .keypad-button:hover {
    background-color: rgba(211, 212, 213, 1); /* Change this to the desired color on press */
    border-color: rgba(198, 199, 200, 1);
    color: #fff!important;
}

.bg-light-gray {
    border-top-width: 3px !important;
}

.invisible-button {
    opacity: 0;
    pointer-events: none; 
}

.invisible-button-clickable,
.invisible-button-clickable:active,
.invisible-button-clickable:focus-visible {
    width: 80px;
    height: 80px;
    background: none !important;
    border: none !important;
    cursor: pointer;
    pointer-events: auto; 
}

.signup-page .name-input {
    margin: 0 5px;
    border: 2px solid #f8f9fa;
    background-color: rgba(255, 255, 255, 0.2);
    color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
}
.signup-page .name-input:focus {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(193, 238, 0, 1);
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    text-align: center;
}
.signup-page .pin-display {
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
}

.signup-page .pin-box {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    border: 2px solid #f8f9fa;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    border-radius: 8px;
    font-weight: bold;
}

.signup-page .pin-box.empty {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter background for empty boxes */
}

.signup-page .keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
}

.signup-page .keypad-button {
    width: 80px;
    height: 80px;
    font-size: 42px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.3)!important; /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    border: none; /* Remove default button border */
    color: #fff; /* Button text color */
    transition: background-color 0.25s ease; /* Add transition for background color */
}

.signup-page .keypad-button.pressed {
    background-color: rgba(255, 255, 255, .7)!important; /* Change this to the desired color on press */
    border-color: rgba(198, 199, 200, 1)!important;
    color: #fff;
}

.signup-page .keypad-button.invisible {
    visibility: hidden;
}

.signup-page .keypad-button:focus {
    outline: none; /* Remove focus outline */
}
.signup-page .keypad-footer {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;
}

.signup-page .signup-nav-link {
    text-align: center;
    flex: 1;
    order: 2;
}

.signup-page .back-admin-nav-link {
    text-align: center;
    flex: 1;
    order: 1;
}

.signup-page .nav-link{color:white}