@font-face {
  font-family: 'InterTight';
  src: local('InterTight'), url('./assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
}

@keyframes zoomOut {
  0% {
      transform: scale(5) rotate(-15deg);
      opacity: 0;
  }
  100% {
      transform: scale(1) rotate(-15deg);
      opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'InterTight'; 
  font-weight: 700; 
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle-edit-image {
  position: absolute;
  top: -55px;
  right: 50px;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 2;
}

.chore-image-container,
.modal-image-container  {
  width: 100%;
  padding-bottom: 133.33%; /* 4:3 aspect ratio (height = 4/3 * width) */
  background-color: #e0e0e0; /* Light gray background color */
  position: relative;
}

.chore-image-spinner,
.modal-image-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute; /* Ensures the spinner stays centered */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #e0e0e0; /* Background color while loading */
}

.chore-image,
.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.c-dash .completed-sticker {
  position: absolute;
  bottom: -20px;
  left: -20px;
  width: 100px;
  height: 100px;
  transform: rotate(-15deg);
  animation: zoomOut 0.25s ease-out;
}
.c-dash .image-loading,
.a-dash .image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.c-dash .image-loaded,
.a-dash .image-loaded {
  width: 100%;
  display: block;
}

.c-dash .image-hidden,
.a-dash .image-hidden {
  display: none;
}

.c-dash .image-icon-container,
.a-dash .image-icon-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
}

.c-dash .image-icon,
.a-dash .image-icon {
  font-size: 2em;
}
